import style from "./style.module.css";
import React from 'react'

function SC() {
  return (
    <div className={`${style.container} container`}>

<div className={`${style.wrapper} wrapper`}>
    <img src="blanche.png" alt=""width={460} heigth={400} />
    <div className={`${style.centered} centered`}><a href="https://stephanecalce.com/film">Films</a>
</div>
  </div>
    </div>
  );
}

export default SC;
